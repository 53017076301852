<template>
  <div>
    <div class="flex justify-center" v-if="loading">
        <propagate-loader class="spinner" color="#E29200" :loading="loading" :size="50"></propagate-loader>
    </div>
    <div v-if="is_url_expiration == false">
      <div class="bg-darkOrange mt-2 lg:mt-5">
          <span class="text-white font-semibold ml-5 lg:ml-20 xl:ml-20 text-3xl md:text-4xl lg:text-4xl xl:text-4xl">Datos del Socio</span>
      </div>

      <div class="ml-5 lg:ml-20 xl:ml-20 mt-5 flex flex-wrap justify-center">
        <div class="w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
          <p class="text-darkOrange">R.F.C</p>
          <p class="font-semibold text-xl">{{rfc}}</p>
        </div>

        <div class="w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
          <p class="text-darkOrange">Nombre Completo</p>
          <p class="font-semibold text-xl">{{name}}</p>
        </div>

        <div class="w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
          <p class="text-darkOrange">Sexo</p>
          <p class="font-semibold text-xl">{{gender}}</p>
        </div>

        <div class="w-full md:w-1/1 lg:w-1/1 xl:w-1/1">
          <p class="text-darkOrange">Tipo de Sangre</p>
          <p class="font-semibold text-xl">{{blood_type}}</p>
        </div>
      </div>

      <div class="bg-darkOrange mt-2 lg:mt-5">
        <span class="text-white font-semibold ml-5 lg:ml-20 xl:ml-20 text-3xl md:text-4xl lg:text-4xl xl:text-4xl">Datos del Domicilio</span>
      </div>

      <div class="ml-5 lg:ml-20 xl:ml-20 mt-5 flex flex-wrap justify-center">
        <div class="w-full md:w-1/4 lg:w-1/4 xl:w-1/4">
          <p class="text-darkOrange">Calle</p>
          <p class="font-semibold text-xl">{{street}}</p>
        </div>

        <div class="w-full md:w-1/4 lg:w-1/4 xl:w-1/4">
          <p class="text-darkOrange">Número Exterior</p>
          <p class="font-semibold text-xl">{{number_exterior}}</p>
        </div>

        <div class="w-full md:w-1/4 lg:w-1/4 xl:w-1/4">
          <p class="text-darkOrange">Número Interior</p>
          <p class="font-semibold text-xl">{{number_interior}}</p>
        </div>

        <div class="w-full md:w-1/4 lg:w-1/4 xl:w-1/4">
          <p class="text-darkOrange">Código Postal</p>
          <p class="font-semibold text-xl">{{postal_code}}</p>
        </div>

        <div class="w-full md:w-1/4 lg:w-1/4 xl:w-1/4">
          <p class="text-darkOrange">Colonia</p>
          <p class="font-semibold text-xl">{{colony}}</p>
        </div>

        <div class="w-full md:w-1/4 lg:w-1/4 xl:w-1/4">
          <p class="text-darkOrange">Municipio</p>
          <p class="font-semibold text-xl">{{township}}</p>
        </div>

        <div class="w-full md:w-1/4 lg:w-1/4 xl:w-1/4">
          <p class="text-darkOrange">Entidad Federativa</p>
          <p class="font-semibold text-xl">{{federal_entity}}</p>
        </div>

        <div class="w-full md:w-1/4 lg:w-1/4 xl:w-1/4">
          <p class="text-darkOrange">Ciudad</p>
          <p class="font-semibold text-xl">{{city}}</p>
        </div>

        <div class="w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
          <p class="text-darkOrange">Correo Electrónico</p>
          <p class="font-semibold text-xl">{{email}}</p>
        </div>

        <div class="w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
          <p class="text-darkOrange">Teléfono</p>
          <p class="font-semibold text-xl">{{phone}}</p>
        </div>
      </div>

      <div class="bg-darkOrange">
        <div class="ml-5 lg:ml-20 xl:ml-20">
          <span class="text-white font-semibold  text-3xl md:text-4xl lg:text-4xl xl:text-4xl">Datos del Centro de Trabajo</span>
        </div>
      </div>

      <div class="ml-5 lg:ml-20 xl:ml-20 mt-5 flex flex-wrap justify-center">
        <div class="w-full md:w-1/4 lg:w-1/4 xl:w-1/4">
          <p class="text-darkOrange">Centro de Trabajo</p>
          <p class="font-semibold text-xl">{{workplace}}</p>
        </div>

        <div class="w-full md:w-1/4 lg:w-1/4 xl:w-1/4">
          <p class="text-darkOrange">Clave</p>
          <p class="font-semibold text-xl">{{work_key}}</p>
        </div>

        <div class="w-full md:w-1/4 lg:w-1/4 xl:w-1/4">
          <p class="text-darkOrange">Teléfono</p>
          <p class="font-semibold text-xl">{{work_number}}</p>
        </div>

        <div class="w-full md:w-1/4 lg:w-1/4 xl:w-1/4">
          <p class="text-darkOrange">Domicilio</p>
          <p class="font-semibold text-xl">{{work_address}}</p>
        </div>

        <div class="w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
          <p class="text-darkOrange">Secretaría Gral. o Representante de CT</p>
          <p class="font-semibold text-xl">{{work_secretary}}</p>
        </div>

        <div class="w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
          <p class="text-darkOrange">Delegación o C.T.</p>
          <p class="font-semibold text-xl">{{work_delegation}}</p>
        </div>

        <div class="w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
          <p class="text-darkOrange">Municipio</p>
          <p class="font-semibold text-xl">{{work_township}}</p>
        </div>
      </div>

      <div class="bg-darkOrange mt-2 lg:mt-5">
        <span class="text-white font-semibold ml-5 lg:ml-20 xl:ml-20 text-3xl md:text-4xl lg:text-4xl xl:text-4xl">Datos Laborales</span>
      </div>

      <div class="ml-5 lg:ml-20 xl:ml-20 mt-5 flex flex-wrap justify-center">
        <div class="w-full md:w-1/1 lg:w-1/1 xl:w-1/1">
          <p class="text-darkOrange">Categoría</p>
          <p class="font-semibold text-xl">{{laboral_category}}</p>
        </div>

        <div class="w-full md:w-1/1 lg:w-1/1 xl:w-1/1">
          <p class="text-darkOrange">Antigüedad</p>
        </div>

        <div class="w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
          <p class="text-darkOrange">Fecha de Ingreso B.C</p>
          <p class="font-semibold text-xl">{{laboral_income_bc}}</p>
        </div>

        <div class="w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
          <p class="text-darkOrange">Fecha de Ingreso SEP</p>
          <p class="font-semibold text-xl">{{laboral_income_sep}}</p>
        </div>

        <div class="w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
          <p class="text-darkOrange">Fecha de Ingreso Serv. Federal</p>
          <p class="font-semibold text-xl">{{laboral_income_federal}}</p>
        </div>
      </div>

      <div class="bg-darkOrange">
        <div class="ml-5 lg:ml-20 xl:ml-20">
          <span class="text-white font-semibold  text-3xl md:text-4xl lg:text-4xl xl:text-4xl">Beneficiarios por fallecimiento del Socio</span>
        </div>
      </div>
    
      <div class="showInSmallScreens text-center">
        <div class="ml-2 mr-2 lg:ml-5 lg:ml-20 xl:ml-20 mt-5 lg:flex-wrap justify-center">
          <div v-for="beneficiaries in beneficiariesDecease" :key="beneficiaries.id" class="w-full">
            <div class="w-full mt-5">
              <p class="text-darkOrange">Nombres</p>
              <p class="font-semibold text-xl">{{beneficiaries.nombre_completo}}</p>
            </div>

            <div class="w-full inline-flex mt-5">
              <div class="w-1/2">
                <p class="text-darkOrange text-center">Parentesco</p>
                <p class="font-semibold text-xl text-center">{{beneficiaries.parentesco}}</p>
              </div>

              <div class="w-1/2">
                <p class="text-darkOrange">Edad</p>
                <p class="font-semibold text-xl">{{beneficiaries.edad}}</p>
              </div>
            </div>

            <div class="w-full mt-5">
              <p class="text-darkOrange text-center">Porcentaje (%)</p>
              <p class="font-semibold text-xl text-center">{{beneficiaries.porcentaje}}</p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div class="hideInSmallScreens">
          <div class="ml-20 mt-5 flex flex-wrap justify-center">
            <div class="w-1/4">
              <p class="text-darkOrange">Nombres</p>
              <p v-for="beneficiaries in beneficiariesDecease" :key="beneficiaries.id" class="font-semibold text-xl">{{beneficiaries.nombre_completo}}</p>
            </div>

            <div class="w-1/4">
              <p class="text-darkOrange text-center">Parentesco</p>
              <p v-for="beneficiaries in beneficiariesDecease" :key="beneficiaries.id" class="font-semibold text-xl text-center">{{beneficiaries.parentesco}}</p>
            </div>

            <div class="w-1/4">
              <p class="text-darkOrange text-center">Edad</p>
              <p v-for="beneficiaries in beneficiariesDecease" :key="beneficiaries.id" class="font-semibold text-xl text-center">{{beneficiaries.edad}}</p>
            </div>

            <div class="w-1/4">
              <p class="text-darkOrange text-center">%</p>
              <p v-for="beneficiaries in beneficiariesDecease" :key="beneficiaries.id" class="font-semibold text-xl text-center">{{beneficiaries.porcentaje}}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-darkOrange mt-5">
          <span class="text-white font-semibold ml-5 lg:ml-20 xl:ml-20 text-3xl md:text-4xl lg:text-4xl xl:text-4xl">Beneficios Adicionales</span>
      </div>
      
      <div v-if="have_savings == true" class="ml-5 lg:ml-20 xl:ml-20 mt-5 flex flex-wrap justify-center">
        <div class="w-full md:w-1/1 lg:w-1/1 xl:w-1/1">
          <p class="text-darkOrange">Ahorro</p>
        </div>

        <div class="w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
          <p class="text-xl">Bonos adicionales en C45</p>
          <p class="text-xl font-semibold">{{saving_additional_bonus_c45}}</p>
        </div>

        <div class="w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
          <p class="text-xl">Descuento Adicional Quincenal</p>
          <p class="text-xl font-semibold">{{saving_fortnight_discount_c45}}</p>
        </div>

        <div class="w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
          <p class="text-xl">Bonos adicionales en F5</p>
          <p class="text-xl font-semibold">{{saving_additional_bonus_f5}}</p>
        </div>

        <div class="w-full md:w-1/2 lg:w-1/2 xl:w-1/2">
          <p class="text-xl">Descuento Adicional Mensual</p>
          <p class="text-xl font-semibold">{{saving_monthly_discount_f5}}</p>
        </div>
      </div>
      <div v-if="have_decease_benefit == true" class="ml-5 lg:ml-20 xl:ml-20 mt-5 flex flex-wrap justify-center">
        <div class="w-full md:w-1/1 lg:w-1/1 xl:w-1/1">
          <p class="text-darkOrange">Potenciación de Beneficio de Defunción</p>
        </div>
        
        <div class="w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
          <p class="text-xl">Módulos</p>
          <p class="text-xl font-semibold">{{decease_number_modules_c3}}</p>
        </div>

        <div class="w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
          <p class="text-xl">Descuento Adicional en el concepto C3</p>
          <p class="text-xl font-semibold">{{decease_additional_monthly_discount_c3}}</p>
        </div>

        <div class="w-full md:w-1/3 lg:w-1/5 xl:w-1/3">
          <p class="text-xl">Beneficio por Defunción</p>
          <p class="text-xl font-semibold">{{decease_benefit}}</p>
        </div>

        <div class="w-full md:w-1/3 lg:w-1/5 xl:w-1/3">
          <p class="text-xl">Beneficio por Invalidez**</p>
          <p class="text-xl font-semibold">{{decease_disability}}</p>
        </div>

        <div class="w-full md:w-1/3 lg:w-1/3 xl:w-1/3">
          <p class="text-xl">Recuperación a 20 años</p>
          <p class="text-xl font-semibold">{{decease_recovery_20}}</p>
        </div>
      </div>

      <div v-if="have_decease_benefit == true" class=" mt-12 flex flex-wrap justify-center text-center">
        <fieldset>
          <legend style="color:black;font-weight:arial;">**Vigencia de 20 años o hasta los 60 años de edad del agremiado, lo que suceda primero</legend>
        </fieldset>
      </div>

      <hr class="showInSmallScreens mt-5 mb-5 bg-darkOrange h-px mx-16">
      <hr class="hideInSmallScreens mt-10 bg-darkOrange h-px mx-24">

      <div class="container mt-10 px-10 lg:px-24">
        <p class="text-xl text-center lg:text-left">
          Acepto los Beneficios Adicionales mencionados y autorizo que el total de las cantidades señaladas sean descontadas de mi sueldo en los conceptos correspondientes y que en caso de haber solicitado la potenciación del Beneficio de Defunción, la recuperación de 20 años está sujeta a que haya realizado todas las aportaciones correspondientes durante ese plazo.
        </p>
        <!-- <p class="text-xl text-center lg:text-left font-semibold mt-10">
          Manifiesto bajo protesta de decir la verdad, que al momento de mi inscripción no padezco ninguna enfermedad. En caso de omitir algún(os) padecimiento(os) deslindado al FAS de la responsabilidad del pago de indemnización. (Anexo copia del talón de cheques y de identificación oficial).
        </p> -->
      </div>

      <div class="text-center mt-10 mb-10 px-10 items-center">
        <!-- <input type="checkbox" class="mr-2" v-model="acceptConditions"> -->
        <span>He leído y acepto las condiciones del </span><a class="text-darkOrange" href="" v-on:click.prevent="getPrivacynotice()">Aviso de Privacidad</a>
      </div>

      <!-- <div class="text-center mt-5 mb-5">
        <button class="bg-darkGray hover:bg-lightGray text-xl py-1 px-20 m-2 rounded-full">
          Sí
        </button>
        <button class="bg-darkGray hover:bg-lightGray text-xl py-1 px-20 m-2 rounded-full">
          No
        </button>
      </div> -->

      <!-- <div class="text-center  mb-5">
        <button
          class="bg-darkGray hover:bg-lightGray text-xl py-1 px-20 m-2 rounded-full"
          v-on:click="
            (acceptConditions = true)
          "
        >
          Sí
        </button>
        <button
          class="bg-darkGray hover:bg-lightGray text-xl py-1 px-20 m-2 rounded-full"
          v-on:click="
            (acceptConditions = false)
          "
        >
          No
        </button>
      </div> -->

      <div class="formAcceptsConditions mt-5 mb-5 flex justify-center">
          <input v-on:click="(acceptConditions = true)" class="boxAcceptsCondittions" type="radio" name="type" value="Si" id="yesForm">
          <label class="labelAcceptsConditions hover:bg-lightGray text-xl py-1 px-16 m-1" for="yesForm">Sí</label>
          <input v-on:click="(acceptConditions = false)" class="boxAcceptsCondittions" type="radio" name="type" value="No" id="noForm">
          <label class="labelAcceptsConditions text-xl hover:bg-lightGray py-1 px-16 m-1" for="noForm">No</label>
      </div>

      <div class="text-center">
        <p class="text-darkOrange text-2xl font-semibold">Firma Digital</p>
        <p class="px-5">Realice su firma como en su identificación oficial</p>
      </div>

      <div class="w-full mt-5">
        <div class="flex flex-col lg:flex-row justify-center">
            <!-- <div class="w-full"></div> -->
          <div class="w-full flex justify-center">
            <VueSignaturePad
                id="signature"
                width="45%"
                height="150px"
                ref="signaturePad"
                :options="options"
            />
          </div>
            <!-- <div class="w-full"></div> -->
        </div>

        <div class="text-center mt-5 mb-5">
          <!-- <div class="w-1/3"></div>
          <div class="w-1/3"> -->
          <!-- <button @click="save" class="mt-5 bg-darkGray hover:bg-lightGray text-xl py-1 px-20 rounded-full">
                  Ver
          </button> -->
          <button @click="undo" class="mt-5 bg-darkGray hover:bg-lightGray text-xl py-1 px-20 rounded-full">
            Deshacer
          </button>
          <!-- </div> -->
          <div class="w-1/3"></div>
        </div>
      </div>

      <div class="text-center px-5" v-if="acceptConditions == false">
        <span class="text-red-600 text-xl font-semibold">Debes aceptar las condiciones del Aviso de Privacidad para continuar</span>
      </div>

      <div class="text-center px-5" v-if="is_signed_empty == true">
        <span class="text-red-600 text-xl font-semibold">Debes ingresar la firma como identificación oficial para continuar</span>
      </div>

      <div class="text-center px-5" v-if="document_signed == true">
        <span class="text-red-600 text-xl font-semibold">El documento ya ha sido firmado</span>
      </div>

      <div class="flex justify-center w-full mt-10 mb-5" v-if="loading_finish">
        <propagate-loader class="w-full flex justify-center spinner" color="#E29200" :loading_finish="loading_finish" :size="50"></propagate-loader>
      </div>

      <div class="text-center mt-5 mb-5" v-if="show_finish">
        <button @click="goToPDF" class="bg-normalYellow hover:bg-hoverYellow text-white text-xl py-1 px-20 rounded-full">
          Finalizar
        </button>
      </div>
    </div>
    <div v-else-if="is_url_expiration == true">
      <div class="bg-darkOrange mt-2 lg:mt-5">
        <span class="text-white font-semibold ml-5 lg:ml-20 xl:ml-20 text-3xl md:text-4xl lg:text-4xl xl:text-4xl">{{errors}}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {  
      acceptConditions: null,
      options: {
          penColor: "#1F2933"
      },
      apiCabsaCore: "https://www.cabsacore.com/",
      //apiCabsaCore: "http://localhost:3000/",
      // apiCabsaCore: "http://200.92.206.58/",
      token_auth_valid: "",
      loading_finish: false,
      show_finish: true,
      is_previous_letter: false,
      is_new_letter: false,
      is_url_expiration: null,
      url_is_expiration: true,
      is_signed_empty:false,
      errors: null,
      status_signed_url: null,
      url_response_json: null,
      url_response_pdf: null,
      url_path_response_json: null,
      url_path_response_pdf: null,
      name_catalog_document: "",
      url_path_response_pdf_signed: null,
      notice_of_privacy_type: "",
      is_not_document_signed: true,
      urlParams: null,
      file: null,
      json_file: null,
      personal_informatiodocument_name: "",
      url_for_download_pdf: "",
      document_signed: false,
      loading: false,
      have_savings: false,
      have_decease_benefit: false,
      document_type: "",
      rfc: "",
      name: "",
      gender: "",
      email: "",
      blood_type: "",
      email_associate: "",
      street: "",
      number_exterior: "",
      number_interior: "",
      postal_code: "",
      colony: "",
      township: "",
      federal_entity: "",
      city: "",
      phone: "",
      workplace: "",
      work_key: "",
      work_number: "",
      work_address: "",
      work_secretary: "",
      work_delegation: "",
      work_township: "",
      laboral_category: "",
      laboral_income_bc: "",
      laboral_income_sep: "",
      laboral_income_federal: "",
      beneficiariesDecease: [{
        "name": "",
        "paternal_name": "",
        "maternal_name": "",
        "middle_name": "",
        "age": "",
        "percentage": "",
        "kinship": ""
      }],
      saving_additional_bonus_c45: "",
      saving_fortnight_discount_c45: "",
      saving_additional_bonus_f5: "",
      saving_monthly_discount_f5: "",
      decease_number_modules_c3: "",
      decease_additional_monthly_discount_c3: "",
      decease_benefit: "",
      decease_disability: "",
      decease_recovery_20: ""
    }
  },
    methods: {
    async goToPDF(){ 
      this.show_finish = false
      this.loading_finish = true
      if (this.acceptConditions == true && this.save().isEmpty == false){
          await this.insertDigitalSignatureToFile()
          await this.postUploadFile()
          if (this.is_not_document_signed == true){
              await this.sendEmail()
              localStorage.setItem("pdf_for_download", this.url_for_download_pdf);
              this.$router.push({ name: 'successful-process'});
          }
          this.is_signed_empty = this.save().isEmpty
          this.document_signed = true
      }
      this.acceptConditions = false
      this.loading_finish = false
      this.show_finish = true
    },
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    save() {
      var result = {
        isEmpty: true,
        data: ""
      }
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if(isEmpty == false){
          result.isEmpty = isEmpty
          result.data = data.split(",")[1]
      }else{
          this.is_signed_empty = isEmpty
      }
      return result
    },
    change() {
      this.options = {
        penColor: "#1F2933"
      };
    },
    resume() {
      this.options = {
        penColor: "#1F2933"
      };
    },
    async insertDigitalSignatureToFile(){
      this.errors = null
      let config = {
        headers: {
        'Content-Type': 'application/json'
        },
      }
      let data = {
        "token_auth": this.token_auth_valid,
        "path_file_to_sign": this.url_path_response_pdf,
        "business_acronym": "FOBEN",
        "name_catalog_document": this.document_type,
        "name_catalog_document_notice_of_privacy": this.notice_of_privacy_type,
        "digital_signature": this.save().data
      }
      await this.axios.post(this.apiCabsaCore + 'uploader/insert_digital_signature_to_file', data, config).then((response) => {
      if (response.data.errores == ""){
        this.url_path_response_pdf_signed = response.data.path
      }else {
          this.errors = true
      }
      this.loading = false
      }).catch(error => {
          this.errors = error.response.data.errores
          this.loading = false
      });
    },
    async getJsonInformation(){
      this.errors = null
      let config = {
        headers: {
          'Content-Type': 'application/json'
        },
      }
      let data = {
        "token_auth": this.token_auth_valid,
        "file_name": this.json_file,
        "business_acronym":"FOBEN",
        "process":"generate_plan_costs_json"
      }
      await this.axios.post(this.apiCabsaCore + 'uploader/get_file_cloud', data, config).then((response) => {
        if (response.data.errores == ""){
          this.url_response_json = response.data.url
          this.getJsonInformationFromUrl();
          this.is_url_expiration = false
        }else {
            this.errors = response.data.errores
        }
        this.loading = false
      }).catch(error => {
        this.errors = error.response.data.errores
        this.loading = false
      });
    },
    async getPdfInformation(){
      this.errors = null
      let config = {
        headers: {
          'Content-Type': 'application/json'
        },
      }
      let data = {
        "token_auth": this.token_auth_valid,
        "file_name": this.file,
        "business_acronym":"FOBEN",
        "process":"generate_plan_costs_pdf"
      }
      await this.axios.post(this.apiCabsaCore + 'uploader/get_file_cloud', data, config).then((response) => {
        if (response.data.errores == ""){
          this.url_response_pdf = response.data.url
          this.url_path_response_pdf = response.data.path
        }else {
          this.errors = response.data.errores
        }
        this.loading = false
      }).catch(error => {
        this.errors = error.response.data.errores
        this.loading = false
      });
    },
    getJsonInformationFromUrl(){
      this.axios.get(this.url_response_json, "", "").then((response) => {
        this.personal_information = response.data.datos_personales
        this.rfc = this.personal_information.rfc
        this.name = this.personal_information.apellido_paterno + " " + this.personal_information.apellido_materno + " " + this.personal_information.nombres
        this.gender = this.getGender()
        this.email = this.personal_information.correo
        this.email_associate = response.data.correo_usuario
        this.blood_type = this.personal_information.tipo_sangre
        this.street = this.personal_information.domicilio.calle
        this.number_exterior = "-"
        this.number_interior =  this.personal_information.domicilio.numero
        this.postal_code = this.personal_information.domicilio.codigo_postal
        this.colony = this.personal_information.domicilio.colonia
        this.township = this.personal_information.municipio
        this.federal_entity = this.personal_information.estado
        this.city = this.personal_information.ciudad
        this.phone = this.personal_information.telefono.lada + this.personal_information.telefono.numero
        this.workplace = response.data.datos_centro_trabajo.centro_trabajo
        this.work_key = response.data.datos_centro_trabajo.clave
        this.work_number = response.data.datos_centro_trabajo.telefono.lada + response.data.datos_centro_trabajo.telefono.numero
        this.work_address = response.data.datos_centro_trabajo.domicilio
        this.work_secretary = response.data.datos_centro_trabajo.representante_ct
        this.work_delegation = response.data.datos_centro_trabajo.delegacion
        this.work_township = response.data.datos_centro_trabajo.municipio
        this.laboral_category = response.data.datos_laborales.categoria
        this.laboral_income_bc = response.data.datos_laborales.fecha_ingreso_bc
        this.laboral_income_sep = response.data.datos_laborales.fecha_ingreso_sep
        this.laboral_income_federal = response.data.datos_laborales.fecha_ingreso_serv_federal
        this.beneficiariesDecease = response.data.beneficiarios
        this.saving_additional_bonus_c45 = response.data.ahorro.bono_adicional_c45 == "." ? "0": response.data.ahorro.bono_adicional_c45
        this.saving_fortnight_discount_c45 = response.data.ahorro.descuento_c45_quincenal == "." ? "$0": "$" + response.data.ahorro.descuento_c45_quincenal
        this.saving_additional_bonus_f5 = response.data.ahorro.bono_adicional_f5 == "." ? "0": response.data.ahorro.bono_adicional_f5
        this.saving_monthly_discount_f5 = response.data.ahorro.descuento_f5_mensual == "." ? "$0.00": "$" + response.data.ahorro.descuento_f5_mensual
        this.decease_number_modules_c3 = response.data.defuncion.numero_modulos_c3 == "." ? "0": response.data.defuncion.numero_modulos_c3
        this.decease_additional_monthly_discount_c3 = response.data.defuncion.descuento_adicional_c3_mensual == "." ? "$0.00": "$" + response.data.defuncion.descuento_adicional_c3_mensual
        this.decease_benefit = response.data.defuncion.beneficio_defuncion == "." ? "$0.00": "$" + response.data.defuncion.beneficio_defuncion
        this.decease_disability = response.data.defuncion.beneficio_invalides == "NO APLICA" ? "NO APLICA": "$" + response.data.defuncion.beneficio_invalides
        this.decease_recovery_20 = response.data.defuncion.recuperacion20 == "." ? "$0.00": "$" + response.data.defuncion.recuperacion20
        this.name_catalog_document = response.data.nombre_catalogo_documento
        if (this.name_catalog_document === "autorizo_descuento_y_f01_foben") {
          this.document_type = "autorizo_descuento_y_f01_foben_firma"
          this.notice_of_privacy_type = "reglamento_no_aplica"
        }
        if (this.name_catalog_document === "formato_f01_foben") {
          this.document_type = "formato_f01_foben_firma"
          this.notice_of_privacy_type = "reglamento_no_aplica"
        }
        if (response.data.ahorro.tiene_ahorro === "x") {
          this.have_savings = true
        }
        if (response.data.defuncion.tiene_defuncion === "x") {
          this.have_decease_benefit = true
        }
      });
    },
    getGender(){
      if(this.personal_information.sexo == "M"){ 
        return "Masculino"
      }
      else{ 
        return "Femenino" 
      }
    },
    async postUrlExpiration(){
      let config = {
          headers: {
              'Content-Type': 'application/json'
          },
      }
      this.loading = true
      await this.axios.post(this.apiCabsaCore + 'uploader/expiry_time', { "token_auth": this.token_auth_valid, "url": window.location.toString(), "business_acronym": "FOBEN"}, config).then((response) => {
        if (response.data.errors.length == 0){
          this.url_is_expiration = false
          return true
        }
      }).catch(error => {
        error.response.data.errors
        this.errors = "El documento que desea firmar ya ha vencido, favor de contactar a su asesor."
        this.url_is_expiration = true
        this.is_url_expiration = true
        return false
      });
      await this.getStatusDigitalSignature()
      if (this.status_signed_url == true){
        this.errors = "El documento que desea firmar ya ha sido firmado, favor de contactar a su asesor."
        this.url_is_expiration = true
        this.is_url_expiration = true
        return false
      }
    },
    async getStatusDigitalSignature(){
      let config = {
        headers: {
            'Content-Type': 'application/json'
        },
      }
      this.status_signed_url = false
      await this.axios.get(this.apiCabsaCore + 'get_status_digital_signature', { params: { "token_auth": this.token_auth_valid, "url": window.location.toString(), "business_acronym": "FOBEN"} }, config).then((response) => {
        if (response.data.url_status == "FIRMADO"){
          this.status_signed_url = true
        }
      })
    },
    async postLogin(){
      let config = {
        headers: {
            'Content-Type': 'application/json'
        },
      }

      let data = {
        "usuario_api": {
            "email": "firma_autorizo@foben.com",
            "password": "cabsa2020"
        }
      }
//await this.axios.post(this.apiCabsaCore + 'appk/login', data, config)
      await this.axios.post(this.apiCabsaCore + 'foben/login', data, config)
        .then((dataa) => {
          this.token_auth_valid = dataa.data.response.token_auth
          localStorage.setItem("token_auth_valid", this.token_auth_valid);
      })
    },
    encryptMessage(){
      const header = { alg: "HS256", typ: "JWT" };
      const encodedHeader = Buffer.from(JSON.stringify(header)).toString(
        "base64"
      );
      var data = {
        html:
          "Informacion del interesado<br>Nombre: " +
          this.name +
          "<br>Correo: " +
          this.email +
          "<br>Celular: " +
          this.phone +
          "<br><br><br>"
      };
      const encodedPayload = Buffer.from(JSON.stringify(data)).toString(
        "base64"
      );
      var jwtSecret = "vM22%!SmnjNV";
      const crypto = require("crypto");
      const signature = crypto
        .createHmac("sha256", jwtSecret)
        .update(encodedHeader + "." + encodedPayload)
        .digest("base64");
      return encodedHeader + "." + encodedPayload + "." + signature;
    },
    async sendEmail(){
      var emailToSendFirst = ""
      var emailToSendSecond = ""
      if (this.email !="" && this.email_associate == "" ){
          emailToSendFirst = this.email
      }
      if (this.email =="" && this.email_associate != ""){
          emailToSendFirst = this.email_associate
      }
      if (this.email !="" && this.email_associate != ""){
          emailToSendFirst = this.email
          emailToSendSecond = this.email_associate
      }
      let emails = [];
      emails.push({ email: emailToSendFirst });
      if (emailToSendSecond != "") {
        emails.push({ email: emailToSendSecond });
      }
      let message = this.encryptMessage()
      this.axios.post("https://www.cabsacore.com/mailer/send_email", {
        mail: {
          business: "FONDO DE BENEFICIOS",
          process_type: "firma designacion beneficiarios",
          title: "Firma Digital FOBEN",
          body_mail: message, 
          mailing_list: emails,
          urls_attachment: [
            {
                file: this.url_for_download_pdf,
                name:"FirmaDigitalFOBEN.pdf"
            }
          ]
        }
      })
      .then(response => {
          console.log(response.data)
          // this.sendedEmailConfirmation()
          this.email = ""
      }).catch(error => {
          console.log(error.data)
      });
    },
    async postUploadFile(){
      let config = {
        headers: {
          'Content-Type': 'application/json'
        },
      }
      let data = {
        "token_auth": this.token_auth_valid,
        "file": this.url_path_response_pdf_signed,
        "document_name": this.document_type,
        "business_acronym":"FOBEN",
        "process":"generate_plan_costs_pdf_signed",
        "extension": "",
        "url_for_sign": window.location.href
        //"https://firma.autorizo.seccion17fas.com/?file=03b51bfc335395a0f5eacf6b074720ba.pdf&json_file=4f6fc7053fbc373bf1851d65cd6624f5.json"
      }
      await this.axios.post(this.apiCabsaCore + 'uploader/upload_signed_file', data, config).then((response) => {
        if (response.data.errores == ""){
          this.url_for_download_pdf = response.data.url
        }else {
            this.is_not_document_signed = false
        }
      }).catch(error => {
        this.errors = error.response.data.errors
        this.is_not_document_signed = false
      });
    },
    getPrivacynotice(){
      window.open("https://cabsacloud.ams3.digitaloceanspaces.com/store/FOBEN/Aviso%20de%20Privacidad%20FOBEN.pdf")
    },
    createArraySendToEmail(){
      let result = [{"email": ""}]
      if( this.email != "" || this.email != null || this.email_associate == "" || this.email_associate == null){
          return result = [{"email": this.email}]
      }
      if( this.email == "" || this.email == null || this.email_associate != "" || this.email_associate != null){
          return result = [{"email": this.email_associate}]
      }
      if( this.email != "" || this.email != null || this.email_associate != "" || this.email_associate != null){
          return result = [{"email": this.email}, {"email": this.email_associate}]
      }
      result
    },
  },
  async beforeMount(){
    this.urlParams = new URLSearchParams(window.location.search)
    this.file = this.urlParams.get('file')
    this.json_file = this.urlParams.get('json_file')
    await this.postLogin()
    await this.postUrlExpiration()
    if (this.url_is_expiration == false){
        this.getJsonInformation()
        this.getPdfInformation()
    }
    this.loading = false
  }
}
</script>

<style>
#signature {
  border: double 2px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #E29200, #E29200);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

@media only screen and (max-width: 650px) {
    .hideInSmallScreens {
        display: none;
    }
}

@media only screen and (min-width: 650px) {
    .showInSmallScreens {
        display: none;
    }
}

.formAcceptsConditions .labelAcceptsConditions{
  padding: 6px 30px;
  border-radius: 20px;
  border: 2px solid  #E4E7EB;
}

.boxAcceptsCondittions:checked + label{
  background: #CBD2D9;
  color: #E4E7EB;
  border: 2px solid #CBD2D9;
}

.boxAcceptsCondittions{
  display: none;
}

</style>
