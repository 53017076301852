  <template>
    <div>
    <div class="flex infline-flex">
        <img class="mt-10" style="display: block;
            margin-left: auto;
            margin-right: auto;
            width:250px;
            height:100px;" alt="Vue logo" src="@/assets/foben.png">
        <img class="mt-10" style="display: block;
            margin-left: auto;
            margin-right: auto;
            width:100px;
            height:95px;" alt="Vue logo" src="@/assets/snte-s2.png">
    </div>
    <div>
      <p class="text-4xl text-center text-black font-bold w-full mt-5">Sindicato Nacional de Trabajadores de la Educación</p>
    </div>
    </div>
</template>

<script>
export default {
  name: 'Header',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
